import React from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";
import { thunkSendMessage, loadData } from "./thunks";

import { ChatState } from "./store/chat/types";
import { dbUpdate } from "./store/chat/actions";

import { FormattedMessage } from 'react-intl';

interface IProps {
  chat: ChatState;
  dbUpdate: typeof dbUpdate;
  loadData: any;
}

//export type UpdateMessageParam = React.SyntheticEvent<{ value: string }>;

//const classes=require('./App.module.scss');

const Main: React.FC<IProps> = (props: IProps) => {
  
  //  props.loadData("items");

	return (<>
			home sweet home
		</>
  );
}

const mapStateToProps = (state: AppState) => ({
	chat: state.chat
});

export default connect(
  mapStateToProps,
  { dbUpdate, loadData }
)(Main);

