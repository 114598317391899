
export enum AppLanguage {
		Suomi = 'fi',
		English = 'en',
}

export enum AppRoute {
	Home = 'routes.home',
	Warehouse = 'routes.warehouse',
	Items = 'routes.items',
	Suppliers = 'routes.suppliers',
	Orders = 'routes.orders',
	Usage = 'routes.usage',
}


