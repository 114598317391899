export const newDate = (s) => {
  let retval=s
  try {
		retval=new Date(s.replace(/\s/, 'T'))

  } catch(err) {
    try {
      retval=new Date(s)
    } catch(err) {
    }
  }

  return retval
}


