import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import { AppState } from "../store";

import { CustomInput, Input, Label, Row, Col, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { IUsage, IPurchaseOrder, IPurchaseOrderRow, IItem, ISupplier, IItemSupplier, EPurchaseOrderStatus } from "../store/chat/types";

import { FormattedMessage } from 'react-intl';

import Select from 'react-select'

import { v4 as uuidv4 } from 'uuid';

import {newDate} from '../orders/newDate';

import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";


interface IProps {
	item: IUsage | null;
	close: (item:IUsage | null)=>void;
}

const EditModal: React.FC<IProps> = ({
	item,
	close,
}) => {
	const [editItem, setEditItem]=useState<IUsage | null>(item);

	const up = (name:string) => (e:any) => {
		const v=e.target?e.target.value:e.value;
		setEditItem({...editItem,[name]:v} as IUsage);
	}

	useEffect(()=>{
		setEditItem(item);
	},[item]);

	return (
		<Modal isOpen={editItem!==null} size="lg">
        <ModalHeader toggle={()=>close(null)}>Edit Item</ModalHeader>
				<ModalBody>
					{editItem && <>
						<div>
							<Label>Date</Label><br/>
							<DatePicker dateFormat="dd.MM.yyyy" selected={editItem.date?newDate(editItem.date):""} onChange={date => setEditItem({...editItem,date:date} as IUsage)}
 />
						</div>

						<Label>Quantity</Label>
						<Input onChange={up("quantity")} value={editItem.quantity}/>


					</>}
        </ModalBody>
				<ModalFooter>
					{editItem &&
					<Button color="primary" onClick={()=>close(editItem)}>Save</Button>}

        </ModalFooter>
			</Modal>
	)

}

const mapStateToProps = (state: AppState) => ({
	items: state.chat.items,
	itemSuppliers: state.chat.itemSuppliers,
	suppliers: state.chat.suppliers,
});

export default connect(
  mapStateToProps,
  {}
)(EditModal);

