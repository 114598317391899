import { IItem, IUsage, IPurchaseOrder, IPurchaseOrderRow } from "../store/chat/types";

import { v4 as uuidv4 } from 'uuid';
import { newDate } from '../orders/newDate'

import { NormSInv } from './NormSInv';

export interface IUsageMathData {
		id:string;
		date:Date;
		item:IItem;
		quantity:number;
}

export interface IOrderMathData {
		id:string;
		date:Date;
		item:IItem;
		quantity:number;
}

export class UsageMath {
	usage: IUsage[];
	orders: IUsage[];
	item: IItem;

	saldo: number;

	reversed: IUsage[];

	constructor(item:IItem, usage:IUsageMathData[], orders:IUsageMathData[]) {
		this.usage=usage;
		this.orders=orders;
		this.item=item;

		const reverseSort = (a:IUsageMathData,b:IUsageMathData) => (a.date>b.date?-1:1)

		this.saldo=this.usage.reduce((resp,ob)=>resp-ob.quantity,0)

		this.reversed=this.usage.sort(reverseSort)
	}

	average() {
		return this.usage.reduce((resp,ob)=>resp+ob.quantity,0)/this.usage.length
	}

	standardDeviation() {
		const avg=this.average()

		return Math.sqrt(this.usage.reduce((resp,ob)=>resp+Math.pow(ob.quantity-avg,2),0)/this.usage.length)
	}

	standardDeviationRange() { //chunk:number=1) {
		const chunk=parseFloat(String(this.item.deliveryTime))/30
		const chunkArray=[]
		for(var i=0;i<this.reversed.length;i++) {
			const c=this.reversed.slice(i,i+chunk)
			if(c.length===chunk) {
				chunkArray.push(c.reduce((resp,ob)=>(resp+ob.quantity),0))
			}
		}
		const avg=chunkArray.reduce((resp,ob)=>resp+ob,0)/chunkArray.length

		return Math.sqrt(chunkArray.reduce((resp,ob)=>resp+Math.pow(ob-avg,2),0)/chunkArray.length)/chunk
	}

	safetyStorage() {
		return NormSInv(parseFloat(String(this.item.serviceLevel))/100)*this.standardDeviationRange()*(parseFloat(String(this.item.deliveryTime))/30)
	}

	reOrderPoint() {
		return this.item.deliveryTime/30*this.average()+this.safetyStorage()
	}

		//=SQRT((2*12*I3*200)/(O3*1.1))
	EOQ() {
		return Math.sqrt((2*12*this.average()*200)/(this.item.unitPrice*1.1))
	}

	forecast(sd:number) {
		const data:{[id:string]:any}=[]; //IUsage[]=[];
		const avg=this.average()+sd;

		const start=newDate(this.reversed[0].date)
    const end=newDate(this.reversed[this.reversed.length-1].date)
    const days=(start.getTime()-end.getTime())/(1000*3600*24)

		let q=this.saldo
		this.reversed.map((ob,index)=> {
			const day=days+2*index
			data.push({
				x:day,
				y:q,
//				id:uuidv4(),
//				item:ob.item,
//				date:ob.date,
//				quantity:q,
			})

			q-=avg;

		});

		return data;
	}

	info() {
		console.log("USAGEMATH");
	}
}


