import React, {useState} from 'react';

import { connect } from "react-redux";
import { AppState } from "../store";
import { thunkSendMessage } from "../thunks";

import { Button } from 'reactstrap';

import { ISupplier } from "../store/chat/types";
import { dbUpdate, modelUpdate } from "../store/chat/actions";

import { FormattedMessage } from 'react-intl';

import { v4 as uuidv4 } from 'uuid';

import EditModal from './EditModal';

import DynamicTable from '../DynamicTable';

interface IProps {
  suppliers: ISupplier[];
  dbUpdate: typeof dbUpdate;
  modelUpdate: typeof modelUpdate;
  thunkSendMessage: any;
}

export type UpdateMessageParam = React.SyntheticEvent<{ value: string }>;

//const classes=require('./App.module.scss');

const Supplier: React.FC<IProps> = ({
	suppliers,
  dbUpdate,
  modelUpdate,
  thunkSendMessage,

}) => {
	
	const [editItem, setEditItem]=useState<ISupplier | null>(null);

	const closeEditItem = (item:ISupplier | null) => {
		if(item!==null) {
      //			const i=[...suppliers]
      //const index=i.findIndex((ob)=>ob.id===item.id)
      //i[index]=item
      //console.log(i)
      //dbUpdate({suppliers:i})
      thunkSendMessage("suppliers",item);

		}
		setEditItem(null); //item);
	}

	const itemtest = () => {
		const i:ISupplier={
      id:"new",
			name:"supplier1",
			deliveryTime:14,
			shippingCosts:1000,
		};
    //dbUpdate({suppliers:[...suppliers,i]})
    thunkSendMessage("suppliers",i);

	}



	const editItemx = (item:ISupplier) => {
		setEditItem(item);
	}

	interface Icolumns {
		label:string;
		variable:keyof ISupplier;
		position:number;
		visible:boolean;
		props?:object;
	}
	const columns:Icolumns[]=[
		{label:'Name',variable:"name",position:0,visible:true},
		{label:'Delivery Time',variable:"deliveryTime",position:1,visible:true,props:{xs:3,className:"text-right"}},
		{label:'Shipping Costs',variable:"shippingCosts",position:2,visible:true,props:{xs:3,className:"text-right"}},
	];


	return (<div>
		<div className="mainheader"><FormattedMessage id="sidebar.suppliers"/></div>

		<DynamicTable onClickRow={editItemx} items={suppliers} columns={columns}/>

		<Button onClick={itemtest}>Add item</Button>
		<EditModal item={editItem} close={closeEditItem}/>
	</div>);
}

const mapStateToProps = (state: AppState) => ({
	suppliers: state.chat.suppliers,
});

export default connect(
  mapStateToProps,
  { dbUpdate, modelUpdate, thunkSendMessage }
)(Supplier);

