import {
  ChatState,
	DB_INITIALIZE,
	DB_UPDATE,
  MODEL_UPDATE,
  ChatActionTypes,
  IItem, emptyItem,
  ISupplier, emptySupplier,
  IItemSupplier, emptyItemSupplier,
  IUsage, emptyUsage,

} from "./types";

const now=new Date();

export const initialState: ChatState = {
	items:[],
	suppliers:[],
	itemSuppliers:[],
	orders:[],
	orderRows:[],
	usage:[],
};


const saveState = (state:ChatState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('db', serializedState);
  } catch {
  }
}

export function chatReducer(
  state = initialState,
  action: ChatActionTypes
): ChatState {
	let retval=null;

  switch (action.type) {
		case DB_UPDATE:
      if(action.payload.usage) {
        const usage=(action.payload.usage || []).map((ob:any)=>{
          const item=state.items.find((io)=>io.id===ob.item)
          return {...ob,date:new Date(ob.date),item}
        })
  			
        retval={
	  	  	...state,
          usage:usage,
  		  }
      }
      else {
  			retval={
	  	  	...state,
	    		...action.payload,
  		  }
      }
			break;

		case MODEL_UPDATE:
     
      let i:IItem[] | ISupplier[] | IItemSupplier[] | IUsage[]=[];
      let item:any=null;

      if(action.model==="items") {
        i=[...state.items]

        item={
          ...emptyItem,
          ...action.payload
        }
      }
      else if(action.model==="suppliers") {
        i=[...state.suppliers]

        item={
          ...emptyItem,
          ...action.payload
        }
      }
      else if(action.model==="itemSuppliers") {
        i=[...state.itemSuppliers]

        item={
          ...emptyItemSupplier,
          ...action.payload
        }
      }
       else if(action.model==="usage") {
        i=[...state.usage]

        item={
          ...emptyUsage,
          ...action.payload,
        }
        item.item=state.items.find((io)=>io.id===item.item)
        item.date=new Date(item.date)
         console.log(item)
      }
   
      if(item!==null) {
     	  const index=i.findIndex((ob:any)=>ob.id===item.id)
     	  if(index===-1) {
           i=[...i,item]
         }
         else {
           i[index]=item
         }

  		  retval={
			  	...state,
          [action.model]:i,
  		  }
      }
			break;

     case DB_INITIALIZE:
        return {
          ...initialState,
          ...action.state,
        }
    default:
  }

	if(retval) {
    //saveState(retval)
    return retval
  }
  return state
}

