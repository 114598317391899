import React, {useState} from 'react';

import { connect } from "react-redux";
import { AppState } from "../store";

import { Button } from 'reactstrap';

import { ISupplier, IPurchaseOrder, EPurchaseOrderStatus } from "../store/chat/types";
import { dbUpdate } from "../store/chat/actions";

import { FormattedMessage } from 'react-intl';

import { v4 as uuidv4 } from 'uuid';

import EditModal from './EditModal';

import DynamicTable from '../DynamicTable';
import {newDate} from './newDate';

interface IProps {
	orders: IPurchaseOrder[];
	dbUpdate: typeof dbUpdate;
}

export type UpdateMessageParam = React.SyntheticEvent<{ value: string }>;

//const classes=require('./App.module.scss');

const Order: React.FC<IProps> = ({
	orders,
	dbUpdate,
}) => {
	
	const [editItem, setEditItem]=useState<IPurchaseOrder | null>(null);

	const closeEditItem = (item:IPurchaseOrder | null) => {
		if(item!==null) {
			const i=[...orders]
			const index=i.findIndex((ob)=>ob.id===item.id)
			if(index===-1) {
				i.push(item);
			}
			else {
				i[index]=item
			}
			dbUpdate({orders:i})
		}
		setEditItem(null); //item);
	}

	const itemtest = () => {
		
		const i:IPurchaseOrder={
			id:uuidv4(),
			status:EPurchaseOrderStatus.DRAFT,
			created:new Date(),
			rows:[],
		};
		//dbUpdate({orders:[...orders,i]})
		 

		setEditItem(i);

	}



	const editItemx = (item:IPurchaseOrder) => {
		setEditItem(item);
	}

	interface Icolumns {
		label:string;
		variable:keyof IPurchaseOrder;
		position:number;
		visible:boolean;
		props?:object;
		show?:(varible:any)=>string;
	}

	const showDate = (d:string | Date):string => {
		return (newDate(d)).printdate()
	}

	const showSupplier = (s:ISupplier):string => {
		return s?s.name:"Unknown"
	}

	const columns:Icolumns[]=[
		{label:'Supplier',variable:"supplier",position:0,visible:true,show:showSupplier},
		{label:'Created',variable:"created",position:1,visible:true,show:showDate},
		{label:'Received',variable:"received",position:3,visible:true,show:showDate},

		{label:'Status',variable:"status",position:2,visible:true},

		//		{label:'Delivery Time',variable:"deliveryTime",position:1,visible:true,props:{xs:3,className:"text-right"}},
		//{label:'Shipping Costs',variable:"shippingCosts",position:2,visible:true,props:{xs:3,className:"text-right"}},
	];

	return (<div>
		<div className="mainheader"><FormattedMessage id="sidebar.orders"/></div>

		<DynamicTable onClickRow={editItemx} items={orders} columns={columns}/>

		<Button onClick={itemtest}>Add item</Button>

		<EditModal item={editItem} close={closeEditItem}/>
	</div>);
}

const mapStateToProps = (state: AppState) => ({
	orders: state.chat.orders,
});

export default connect(
  mapStateToProps,
  { dbUpdate }
)(Order);

