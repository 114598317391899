export {}

declare global {
	interface Date {
		printdate(): string;
	}
}

//eslint-disable-next-line no-extend-native
Date.prototype.printdate = function() {
			if(isNaN(this.getTime())) { return "" }

		  var date = new Date(this.getTime());
			let day = "00"+date.getDate();
	  	let month = "00"+(date.getMonth()+1);
		  let year = date.getFullYear();

			return day.substring(day.length-2)+"."+month.substring(month.length-2)+"."+year
			
		}
