import React from 'react';

import { connect } from "react-redux";
import { AppState } from "../store";

import { IUsage, IPurchaseOrder, IPurchaseOrderRow, EPurchaseOrderStatus } from "../store/chat/types";
import { dbUpdate } from "../store/chat/actions";

import { FormattedMessage } from 'react-intl';

import DynamicTable from '../DynamicTable';

interface IProps {
	orders: IPurchaseOrder[];
	usage: IUsage[];
	dbUpdate: typeof dbUpdate;
}

export type UpdateMessageParam = React.SyntheticEvent<{ value: string }>;

//const classes=require('./App.module.scss');

const Order: React.FC<IProps> = ({
	orders,
	usage,
	dbUpdate,
}) => {

	interface Icolumns {
		label:string;
		variable:keyof IPurchaseOrder;
		position:number;
		visible:boolean;
		props?:object;
		show?:(varible:any)=>string;
	}

	interface IWarehouseItem {
		id: string;
		SKU: string;
  	name1: string;
		unitPrice: number;
		quantity: number;
	}

	const orderRows:{[id:string]:IWarehouseItem}=orders
		.filter((ob)=>(ob.status===EPurchaseOrderStatus.RECEIVED))
		.reduce((resp:{[id:string]:IWarehouseItem},ob:IPurchaseOrder)=>{
		
			const rows=(ob.rows || []).reduce((resp2:{[id:string]:IWarehouseItem},ob2:IPurchaseOrderRow)=>{
				if(ob2.itemSupplier) {
					const x:IWarehouseItem=resp2[ob2.itemSupplier.item.id] || {...ob2.itemSupplier.item,quantity:0};
					x.quantity+=parseFloat(String(ob2.receivedQuantity) || "0")
					
					resp[ob2.itemSupplier.item.id]=x
					return resp

				}
				return resp2
			},resp)

			return rows
		},{})

	const usageRows:{[id:string]:IWarehouseItem}=usage
		.reduce((resp:{[id:string]:IWarehouseItem},ob:IUsage)=>{
			const x:IWarehouseItem=resp[ob.item.id] || {...ob.item,quantity:0};
			x.quantity-=parseFloat(String(ob.quantity) || "0")
			
			resp[ob.item.id]=x
			return resp

		},orderRows)

	interface Icolumns2 {
		label:string;
		variable:keyof IWarehouseItem;
		position:number;
		visible:boolean;
		props?:object;
		show?:(varible:any)=>string;
	}

		const columns2:Icolumns2[]=[
		{label:'Item',variable:"name1",position:0,visible:true},
		{label:'Quantity',variable:"quantity",position:1,visible:true},


		//		{label:'Delivery Time',variable:"deliveryTime",position:1,visible:true,props:{xs:3,className:"text-right"}},
		//{label:'Shipping Costs',variable:"shippingCosts",position:2,visible:true,props:{xs:3,className:"text-right"}},
	];



	return (<div>
		<div className="mainheader"><FormattedMessage id="sidebar.warehouse"/></div>

		{/*<DynamicTable onClickRow={()=>console.log("edit")} items={Object.values(orderRows)} columns={columns2}/>*/}

		<DynamicTable onClickRow={()=>console.log("edit")} items={Object.values(usageRows)} columns={columns2}/>

	</div>);
}

const mapStateToProps = (state: AppState) => ({
	orders: state.chat.orders,
	usage: state.chat.usage,
});

export default connect(
  mapStateToProps,
  { dbUpdate }
)(Order);

