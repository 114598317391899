import React from 'react';

import { connect } from "react-redux";
import { AppState } from "../store";

import { Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { IItem, ISupplier, IItemSupplier } from "../store/chat/types";

import { FormattedMessage } from 'react-intl';

import { dbUpdate, modelUpdate } from "../store/chat/actions";
import { thunkSendMessage } from "../thunks";

import { v4 as uuidv4 } from 'uuid';

interface IProps {
	item: IItem | null;
	close: (item:IItem | null)=>void;
	supplier: ISupplier | null;
	itemSuppliers:IItemSupplier[];
  dbUpdate: typeof dbUpdate;
  thunkSendMessage: any;
}

//const classes=require('./App.module.scss');

const EditItemSupplierModal: React.FC<IProps> = ({
	item,
	close,
	supplier,
	itemSuppliers,
  dbUpdate,
  thunkSendMessage,

}) => {

	const onSave = () => {
		if(item && supplier) {
			const i:IItemSupplier={
        id:"new", //uuidv4(),

				item:item,
				supplier:supplier,

				SKU:item.SKU,
				name1:item.name1,
				unitPrice:item.unitPrice,
				shippingCosts:supplier.shippingCosts,
			};

			console.log(i)
      //			dbUpdate({itemSuppliers:[...itemSuppliers,i]})
      
      thunkSendMessage("itemSuppliers",i)
		}
		close(null);
	}

	return (
		<Modal isOpen={supplier!==null}>
        <ModalHeader toggle={()=>close(null)}>Edit Item</ModalHeader>
				<ModalBody>
					{supplier && item && <>
						{item.SKU} {item.name1}
						<br/>
						{supplier.name}

					</>}
        </ModalBody>
        <ModalFooter>
					<Button color="primary" onClick={onSave}>Save</Button>

        </ModalFooter>
			</Modal>
	)

}

const mapStateToProps = (state: AppState) => ({
	itemSuppliers: state.chat.itemSuppliers,
});

export default connect(
  mapStateToProps,
  { dbUpdate, thunkSendMessage }

)(EditItemSupplierModal);

