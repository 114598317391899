import React, { useState } from 'react';
import { connect } from "react-redux";
import { AppState } from "./store";

import { Route, BrowserRouter as Router } from 'react-router-dom'

import { LocalizedSwitch, LocalizedRouter, appStrings } from './i18n';
import { AppLanguage, AppRoute } from './i18n/const';

import NotFound from './NotFound';

import Main from './Main';

import Item from './items/Item';
import Supplier from './suppliers/Supplier';
import Order from './orders/Order';
import Warehouse from './warehouse/Warehouse';

import './App.css';

import Usage from './usage/Usage';

import MenuBar from './MenuBar';

import { loadData } from './thunks'; 

interface IProps {
  defaultLanguage?: AppLanguage;
  loadData:any;
}


const App: React.FC<IProps> = (props: IProps) => {
  props.loadData("items");
  props.loadData("suppliers");
  props.loadData("itemSuppliers");
  setTimeout(()=>{props.loadData("usage");},1000)
	return (
	<LocalizedRouter
		RouterComponent={Router}
		languages={AppLanguage}
		appStrings={appStrings}
		defaultLanguage={props.defaultLanguage}
	>
		<MenuBar/>

		<div className="main">
			<LocalizedSwitch>
				<Route exact path={AppRoute.Home} component={Main} />
				<Route exact path={AppRoute.Usage} component={Usage} />
				<Route exact path={AppRoute.Items} component={Item} />
				<Route exact path={AppRoute.Suppliers} component={Supplier} />
				<Route exact path={AppRoute.Orders} component={Order} />
				<Route exact path={AppRoute.Warehouse} component={Warehouse} />

				<Route component={NotFound} />
			</LocalizedSwitch>
		</div>

 	</LocalizedRouter>


  );
}

const mapStateToProps = (state: AppState) => ({
  //	chat: state.chat
});

export default connect(
  mapStateToProps,
  { loadData }
)(App);

//export default App;
