import React, {useState} from 'react';

import { connect } from "react-redux";
import { AppState } from "../store";

import { Button } from 'reactstrap';

import { IItem } from "../store/chat/types";
import { dbUpdate, modelUpdate } from "../store/chat/actions";

import { FormattedMessage } from 'react-intl';

import { v4 as uuidv4 } from 'uuid';

import EditModal from './EditModal';
import DynamicTable from '../DynamicTable';

import { thunkSendMessage } from '../thunks';

interface IProps {
  items: IItem[];
  dbUpdate: typeof dbUpdate;
  modelUpdate: typeof modelUpdate;
  thunkSendMessage: any;
}

export type UpdateMessageParam = React.SyntheticEvent<{ value: string }>;

//const classes=require('./App.module.scss');

const Item: React.FC<IProps> = ({
	items,
  dbUpdate,
  modelUpdate,
  thunkSendMessage,
}) => {
	
	const [editItem, setEditItem]=useState<IItem | null>(null);

	const closeEditItem = (item:IItem | null) => {
		if(item!==null) {
      //const i=[...items]
      //const index=i.findIndex((ob)=>ob.id===item.id)
      //i[index]=item
      //dbUpdate({items:i})
      thunkSendMessage("items",item);
		}
		setEditItem(null); //item);
	}

	const itemtest = () => {
		const i:IItem={
      id:"new", //uuidv4(),
			SKU:"1001",
			name1:"tuote1",
			unitPrice:0,
			serviceLevel:80,
			deliveryTime:90,
		};
    //dbUpdate({items:[...items,i]})
    //
    console.log("thunk");
    thunkSendMessage("items",i);
	}



	const editItemx = (item:IItem) => {
		setEditItem(item);
	}

	interface Icolumns {
		label:string;
		variable:keyof IItem;
		position:number;
		visible:boolean;
		props?:object;
	}
	const columns:Icolumns[]=[
		{label:'SKU',variable:"SKU",position:0,visible:true,props:{xs:2}},
		{label:'Name',variable:"name1",position:1,visible:true},
		{label:'Price',variable:"unitPrice",position:2,visible:true,props:{xs:3,className:"text-right"}},
		{label:'Service Level',variable:"serviceLevel",position:3,visible:true,props:{xs:3,className:"text-right"}},
		{label:'Delivery Time',variable:"deliveryTime",position:4,visible:true,props:{xs:3,className:"text-right"}},
	];


	return (<div>
		<div className="mainheader"><FormattedMessage id="sidebar.items"/></div>

		<DynamicTable onClickRow={editItemx} items={items} columns={columns}/>

		<Button onClick={itemtest}>Add item</Button>
		<EditModal item={editItem} close={closeEditItem}/>
	</div>);
}

const mapStateToProps = (state: AppState) => ({
	items: state.chat.items,
});

export default connect(
  mapStateToProps,
  { dbUpdate, modelUpdate, thunkSendMessage }
)(Item);

