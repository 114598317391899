import React from 'react';

import { Row, Col } from 'reactstrap';

import { FormattedMessage } from 'react-intl';

interface IProps {
	items: any[];
	onClickRow: (item:any) => void;
	columns:any[];
}

const classes=require('./DynamicTable.module.scss');

const DynamicTable: React.FC<IProps> = ({
	items,
	onClickRow,
	columns,
}) => {

	const columnFilter = (ob:any) => (ob.visible)

	const columnSort = (a:any,b:any) => (a.position>b.position?1:-1)

	const cols=columns.filter(columnFilter).sort(columnSort);

	return (<div className={classes.table}>
		<Row className={classes.header}>
			{cols.map((ob)=>(
				<Col key={ob.label} {...ob.props}>{ob.label}</Col>
			))}
		</Row>

		{items.map((item)=>(
			<Row key={item.id} className={classes.selectablerow} onClick={()=>onClickRow(item)}>
				{cols.map((ob)=>(
					<Col key={ob.variable} {...ob.props}>{ob.show?ob.show(item[ob.variable]):item[ob.variable]}</Col>
				))}
			</Row>
		))}

	</div>);
}

export default DynamicTable;

