import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import { AppState } from "../store";

import { Input, Label, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { IItem, ISupplier, IItemSupplier } from "../store/chat/types";

import { FormattedMessage } from 'react-intl';

import Select from 'react-select'

import EditItemSupplierModal from './EditItemSupplierModal';

interface IProps {
	item: IItem | null;
	close: (item:IItem | null)=>void;
	suppliers: Array<ISupplier>;
	itemSuppliers: Array<IItemSupplier>;
}


//const classes=require('./App.module.scss');

const EditModal: React.FC<IProps> = ({
	item,
	close,
	suppliers,
	itemSuppliers,
}) => {
	const [editItem, setEditItem]=useState<IItem | null>(item);
	const [editSupplier, setEditSupplier]=useState<ISupplier | null>(null);

	const up = (name:string) => (e:any) => {
			setEditItem({...editItem,[name]:e.target.value} as IItem)
	}

	useEffect(()=>{
		setEditItem(item);
	},[item]);

	const addSupplier = (a:any) => {
		setEditSupplier(a.value);
	}

	const selectedSuppliers=(editItem && itemSuppliers.filter((ob)=>ob.item.id===editItem.id)) || []

	const options = suppliers.filter((ob)=>( !selectedSuppliers.map((ob2)=>ob2.supplier.id).includes(ob.id) )).map((ob)=>(
		{ value:ob, label: ob.name}
	))


	return (<>
		<Modal isOpen={editItem!==null}>
        <ModalHeader toggle={()=>close(null)}>Edit Item</ModalHeader>
				<ModalBody>
					{editItem && <>
					
						<Label>SKU</Label>
						<Input onChange={up("SKU")} value={editItem.SKU}/>

						<Label>Name1</Label>
						<Input onChange={up("name1")} value={editItem.name1}/>

						<Label>Price</Label>
						<Input type="number" onChange={up("unitPrice")} value={editItem.unitPrice}/>

						<Label>Service Level</Label>
						<Input onChange={up("serviceLevel")} value={editItem.serviceLevel}/>
						<Label>Delivery Time</Label>
						<Input onChange={up("deliveryTime")} value={editItem.deliveryTime}/>





						<Label>Suppliers</Label>
						{selectedSuppliers.map((s)=>(
							<div key={s.id}>{s.supplier.name}</div>
						))}

						{options.length>0 &&
							<Select options={options} onChange={addSupplier} placeholder="Add supplier"/>}

					</>}
        </ModalBody>
        <ModalFooter>
					<Button color="primary" onClick={()=>close(editItem)}>Save</Button>

        </ModalFooter>
			</Modal>
			<EditItemSupplierModal item={editItem} supplier={editSupplier} close={()=>setEditSupplier(null)}/>
		</>)

}

const mapStateToProps = (state: AppState) => ({
	suppliers: state.chat.suppliers,
	itemSuppliers: state.chat.itemSuppliers,
});

export default connect(
  mapStateToProps,
	{}
)(EditModal);

