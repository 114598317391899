import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import { AppState } from "../store";

import { CustomInput, Input, Label, Row, Col, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { IPurchaseOrder, IPurchaseOrderRow, IItem, ISupplier, IItemSupplier, EPurchaseOrderStatus } from "../store/chat/types";

import { FormattedMessage } from 'react-intl';

import Select from 'react-select'

import { v4 as uuidv4 } from 'uuid';

import {newDate} from './newDate';

import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";


interface IProps {
	items: Array<IItem>;
	itemSuppliers: Array<IItemSupplier>;
	suppliers: Array<ISupplier>;
	item: IPurchaseOrder | null;
	close: (item:IPurchaseOrder | null)=>void;
}

//const classes=require('./App.module.scss');

const EditModal: React.FC<IProps> = ({
	items,
	itemSuppliers,
	suppliers,
	item,
	close,
}) => {
	const [editItem, setEditItem]=useState<IPurchaseOrder | null>(item);
	const [editItemRows, setEditItemRows]=useState<IPurchaseOrderRow[]>([]);

	const [startDate, setStartDate] = useState<Date | null>(new Date());

	const up = (name:string) => (e:any) => {
		const v=e.target?e.target.value:e.value;
		setEditItem({...editItem,[name]:v} as IPurchaseOrder)
	}

	const emptyRow:IPurchaseOrderRow = {
		id:uuidv4(),
		//		purchaseOrder:editItem,
		itemSupplier:null,
		unitPrice:0,
		orderedQuantity:0,
		shippedQuantity:0,
		receivedQuantity:0,
	}

	const updateRows = (index:number, name:string) => (e:any) => {
		const v=e.target?e.target.value:e.value;
		const rows=[...editItemRows];
		const row:IPurchaseOrderRow={...rows[index] || emptyRow,[name]:v}
		rows[index]=row;
		setEditItemRows(rows);
	}


	useEffect(()=>{
		setEditItem(item);
		setEditItemRows((item && item.rows) || []);
	},[item]);
	const itemSupplierFilter = (ob:IItemSupplier) => (
		editItem && editItem.supplier && ob.supplier && (ob.supplier.id===editItem.supplier.id)
	)

	const itemOptions = itemSuppliers.filter(itemSupplierFilter).map((ob)=>(
		{ value:ob, label: ob.SKU+" "+ob.name1}
	)) || []

	const supplierOptions = suppliers.map((ob)=>(
		{ value:ob, label: ob.name}
	)) || []

	return (
		<Modal isOpen={editItem!==null} size="lg">
        <ModalHeader toggle={()=>close(null)}>Edit Item</ModalHeader>
				<ModalBody>
					{editItem && <>
					<Label>Supplier</Label>						
						{editItem.supplier
							?<h5>{editItem.supplier.name}</h5>
							:<Select options={supplierOptions} onChange={up("supplier")} placeholder="Select supplier"/>}
	
						<Label>Status</Label><br/>

						<CustomInput id="r1" name="status" type="radio" onChange={up("status")} value={EPurchaseOrderStatus.DRAFT} checked={editItem.status===EPurchaseOrderStatus.DRAFT} inline label="Draft"/>
						<CustomInput id="r2" name="status" type="radio" onChange={up("status")} value={EPurchaseOrderStatus.ORDERED} checked={editItem.status===EPurchaseOrderStatus.ORDERED} inline label="Ordered"/>
						<CustomInput id="r3" name="status" type="radio" onChange={up("status")} value={EPurchaseOrderStatus.SHIPPED} checked={editItem.status===EPurchaseOrderStatus.SHIPPED} inline label="Shipped"/>
						<CustomInput id="r4" name="status" type="radio" onChange={up("status")} value={EPurchaseOrderStatus.RECEIVED} checked={editItem.status===EPurchaseOrderStatus.RECEIVED} inline label="Received"/>
						<CustomInput id="r5" name="status" type="radio" onChange={up("status")} value={EPurchaseOrderStatus.DELETED} checked={editItem.status===EPurchaseOrderStatus.DELETED} inline label="Deleted"/>

						<br/>

						<div>
							<Label>Received</Label><br/>
							<DatePicker dateFormat="dd.MM.yyyy" selected={editItem.received?newDate(editItem.received):""} onChange={date => setEditItem({...editItem,received:date} as IPurchaseOrder)}
 />
						</div>


						<Label>Delivery Time</Label>
						<Input onChange={up("deliveryTime")} value={editItem.deliveryTime}/>

						<Label>Shipping Costs</Label>
						<Input onChange={up("shippingCosts")} value={editItem.shippingCosts}/>

						<Label>Items</Label>

							<Row>
								<Col>
									Item
								</Col>
								<Col>
									<Label>Unit Price</Label>
								</Col>
								<Col>
									<Label>Ordered quantity</Label>
								</Col>
								<Col>
									<Label>Shipped quantity</Label>
								</Col>
								<Col>
									<Label>Received quantity</Label>
								</Col>
							</Row>

						{editItemRows.map((row,index)=>(<div key={index}>
							<Row>
								<Col>
									{row.itemSupplier && <>{row.itemSupplier.SKU} {row.itemSupplier.name1}</>}
								</Col>

								<Col>
									<Input onChange={updateRows(index,"unitPrice")} value={row.unitPrice}/>
								</Col>

								<Col>
									<Input onChange={updateRows(index,"orderedQuantity")} value={row.orderedQuantity}/>
								</Col>
								<Col>
									<Input onChange={updateRows(index,"shippedQuantity")} value={row.shippedQuantity}/>
								</Col>
								<Col>
									<Input onChange={updateRows(index,"receivedQuantity")} value={row.receivedQuantity}/>
								</Col>
							</Row>
						</div>))}

						<Select options={itemOptions} onChange={updateRows(editItemRows.length,"itemSupplier")} placeholder="Add item"/>


					</>}
        </ModalBody>
				<ModalFooter>
					{editItem &&
					<Button color="primary" onClick={()=>close({...editItem,rows:editItemRows})}>Save</Button>}

        </ModalFooter>
			</Modal>
	)

}

const mapStateToProps = (state: AppState) => ({
	items: state.chat.items,
	itemSuppliers: state.chat.itemSuppliers,
	suppliers: state.chat.suppliers,
});

export default connect(
  mapStateToProps,
  {}
)(EditModal);

