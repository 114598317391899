import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppState } from "./store";

import { dbUpdate, modelUpdate } from "./store/chat/actions";

import axios from 'axios';
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const hostname = window && window.location && window.location.hostname;
let apiPath="/api/";
if(hostname==="localhost") {
  apiPath="http://localhost:8000/api/";
}


export const thunkSendMessage = (  
  type: string, payload:any
): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
  

  axios.post(apiPath+type+"/",payload)
    .then((resp)=>{

      dispatch(
        modelUpdate(type,resp.data)
      );

    })
    .catch((resp)=>{
      console.log("ERR",resp);
    })

};

export const loadData = (  
  model: string
): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
  axios.get(apiPath+model+"/")
    .then((resp)=>{

      dispatch(
        dbUpdate({[model]:resp.data})
      );

    })
    .catch((resp)=>{
      console.log("ERR",resp);
    })

};


