import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../index";

import { Message,
  //SEND_MESSAGE, UPDATE_MESSAGE, DELETE_MESSAGE,
  DB_INITIALIZE,
	DB_UPDATE,
  MODEL_UPDATE,
	ChatActionTypes,
} from './types'

import { initialState } from './reducers'
/*
// TypeScript infers that this function is returning SendMessageAction
export function sendMessage(newMessage: Message): ChatActionTypes {
  return {
    type: SEND_MESSAGE,
    payload: newMessage
  }
}

// TypeScript infers that this function is returning SendMessageAction
export function updateMessage(message: Message): ChatActionTypes {
  return {
    type: UPDATE_MESSAGE,
    payload: message
  }
}


// TypeScript infers that this function is returning DeleteMessageAction
export function deleteMessage(timestamp: number): ChatActionTypes {
  return {
    type: DELETE_MESSAGE,
    meta: {
      timestamp
    }
  }
}
*/

export function dbInitialize():ChatActionTypes {
  let data=localStorage.getItem("db")
	let state=initialState
  try {
    state=JSON.parse(data || "")
  } catch(err) {
  }

  return({
		type:DB_INITIALIZE,
    state,
  })
}

function exampleAPI() {
  return Promise.resolve("Async Chat Bot");
}
/*
export const modelUpdate2 = (model:string,payload:any) => {
  console.log("JJ")
}

export const modelUpdate = (model:string,payload:any): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
  const asyncResp = await exampleAPI();
    dispatch({
    	type:MODEL_UPDATE,
      payload: payload,
    })

};
*/

export function modelUpdate(model:string, payload: any):ChatActionTypes {
  console.log("UPDATE",model,payload)
	return {
		type:MODEL_UPDATE,
    model:model,
    payload: payload,
  }
}

export function dbUpdate(payload: any):ChatActionTypes {
	return {
		type:DB_UPDATE,
    payload: payload,
  }

}
