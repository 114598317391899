import React, { useState, useRef, useEffect } from 'react';

import { connect } from "react-redux";
import { AppState } from "../store";
import { thunkSendMessage } from "../thunks";

import { dbUpdate } from "../store/chat/actions";

import { IItem, IUsage, IPurchaseOrder, IPurchaseOrderRow } from "../store/chat/types";

import {
  ComposedChart, Line, Bar, YAxis, CartesianGrid, Tooltip, XAxis,
  Legend, Scatter, ScatterChart
} from 'recharts';

import { Button, Row, Col } from 'reactstrap';

import CSVReader from 'react-csv-reader'

import { newDate } from '../orders/newDate'

import { v4 as uuidv4 } from 'uuid';

import { UsageMath, IUsageMathData } from './UsageMath';

import EditModal from './EditModal';

interface IProps {
	items: IItem[];
	usage: IUsage[];
	orders: IPurchaseOrder[];
  dbUpdate: typeof dbUpdate;
  thunkSendMessage: any;

}

const COLORS = [
	"#000000",
	"#ff0000",
	"#0ff000",
	"#00ff00",
	"#000ff0",
	"#0000ff",
]

const round = (f:number) => {
	return (Math.round(f*100)/100).toFixed(2)
}

const Example:React.FC<IProps>= ({
	items,
	usage,
	orders,
  dbUpdate,
  thunkSendMessage,

}) => {
	const [ROP,setROP]=useState<number>(45);
	const [leadTime,setLeadTime]=useState<number>(14);
	const [SS,setSS]=useState<number>(10);
	const [EOQ,setEOQ]=useState<number>(105);
	const [sellsOut,setSellsOut]=useState<number>(40);

  const [selectedItems, setSelectedItems]=useState<string[]>([]);
  const [selectedDetails, setSelectedDetails]=useState<string[]>([]);

  const [editUsage, setEditUsage]=useState<IUsage | null>(null);

	let inStock:number=118;

	const [chartWidth,setChartWidth]=useState(0);
	const chartRef=useRef<HTMLDivElement>(null);
	useEffect(()=>{
		setChartWidth((chartRef && chartRef.current && chartRef.current.offsetWidth) || 0)
	},[chartRef])

	const stock=[];
	let fill=null;

	interface IChartData {
		id:string;
		date:Date;
		item:IItem;
		quantity:number;
	}

  const chartSort = ((a:IChartData,b:IChartData) => {
		return a.date<b.date?-1:1
	})


  const [chartData,setChartData]=useState<Array<IChartData>>([]);
  
  let sortedUsage=chartData.sort(chartSort);

  useEffect(()=>{
    const _chartData:Array<IChartData>=[];
  	usage.forEach((ob)=>{
  		_chartData.push({
  			id:ob.id,
  			date:ob.date,
  			item:ob.item,
  			quantity:-ob.quantity,
  		})
    })

  	orders.filter((ob)=>(ob.received)).forEach((ob)=>{
  		ob.rows.forEach((row)=>{
  			if(ob.received && row.itemSupplier && row.receivedQuantity) {
  			_chartData.push({
  				id:row.id,
  				date:ob.received,
  				item:row.itemSupplier.item,
  				quantity:parseFloat(String(row.receivedQuantity)),
  			})
  			}
  		})
  	})

    setChartData(_chartData)

    sortedUsage=_chartData.sort(chartSort);

  },[usage,orders]);


	const start=sortedUsage.length>0?newDate(sortedUsage[0].date):newDate();
  //const end=sortedUsage.length>0?newDate(sortedUsage[sortedUsage.length-1].date):newDate();
  //const days=(end-start)/1000/60/60/24;

	const filterData = (SKU:string):{usage:IUsageMathData[],orders:IUsageMathData[]} => {
		return {
			usage: usage.filter((ob)=>ob.item.SKU===SKU),
		
			orders: orders.filter((ob)=>ob.received).reduce((resp:IUsageMathData[],ob)=>{
				ob.rows.forEach((row)=>{
					if(ob.received && row.itemSupplier && row.itemSupplier.item && row.itemSupplier.item.SKU===SKU) {
						resp.push({
							id:row.id,
							date:ob.received,
							item:row.itemSupplier.item,
							quantity:row.receivedQuantity || 0,
						})
					}
				})
				return resp
			},[])
		}
	}

	const usageDatax=sortedUsage.reduce((resp:{[id:string]:any},ob:any,index:number)=>{
		const color:string=COLORS[index%COLORS.length]

    if(selectedItems.includes(ob.item.id)) {
  		const a=resp[ob.item.SKU] || {item:ob.item,name:ob.item.SKU,q:0,usage:[],color:color}
		  a.q+=ob.quantity
	  	const day=(newDate(ob.date).getTime()-start.getTime())/1000/60/60/24
  		a.usage.push({x:day,y:a.q})
      resp[ob.item.SKU]=a
    }
		return resp
	},{})
		/*
	for(let i=0;i<100;i++) {
		inStock=inStock-(EOQ/sellsOut)
		stock.push({name:"q",stock:Math.floor(inStock),ROP:ROP,SS:SS})

		if(fill!==null) {
			if(fill>0) {
				fill--;
			}
			else {
				fill=null;
				inStock+=EOQ;
			}
		}

		if(fill===null && inStock<ROP+SS) {
			fill=leadTime;
		}

	}
		 */
	interface IUsageCSV {
		SKU:string,
		quantity:number;
		date:string;
	}

	const handleUpload = (data:Array<any>, fileInfo:any) => {
		const u:IUsage[]=[] //[...usage]

		data.forEach((ob:IUsageCSV)=>{
			if(ob && ob.SKU && ob.date) {
				const item:IItem | undefined=items.find((i)=>String(i.SKU)===String(ob.SKU))
				if(item) {
					const [ d,m,y ]=ob.date.split(".")
					const date:Date=new Date(parseInt(y),parseInt(m)-1,parseInt(d))

					const newUsage:IUsage={
            id:"new", //uuidv4(),
						item:item,
						date:date,
						quantity:parseFloat(String(ob.quantity)),
					}

					const index=u.findIndex((ob)=>(ob.item.SKU===item.SKU && newDate(ob.date).printdate()===date.printdate()))
          if(index===-1) {
            console.log("send",newUsage)
            //						u.push(newUsage);
            thunkSendMessage("usage",newUsage)
					}
					else {
            //u[index]=newUsage;
					}
				}

			}
		})

    //		dbUpdate({usage:u})
	}

  const saveUsage = (item:IUsage | null) => {
    if(item) {
      console.log(item)
      thunkSendMessage("usage",item);
      setEditUsage(null)
    }
  }

	const usageMaths:{[id:string]:any}={}

	Object.values(usageDatax).forEach((ob:any,index:number)=>{

		const {usage:u3,orders:o3}=filterData(ob.name)
		const usageMath=new UsageMath(ob.item,u3,o3)
		usageMaths[ob.item.id]=usageMath
	})

  const selectItems = (id:string) => {
    const newItems=[...selectedItems]
    let index=newItems.indexOf(id)
    if(index===-1) {
      setSelectedItems([...newItems,id])
    }
    else {
      newItems.splice(index,1)
      setSelectedItems(newItems)
    }
  }

  const toggleDetails = (id:string) => {
    const newItems=[...selectedDetails]
    let index=newItems.indexOf(id)
    if(index===-1) {
      setSelectedDetails([...newItems,id])
    }
    else {
      newItems.splice(index,1)
      setSelectedDetails(newItems)
    }

  }

	return (
		<div ref={chartRef}>
      {false && <CSVReader parserOptions={{header:true}} onFileLoaded={handleUpload} />}
			{/*(data, fileInfo) => console.dir(data, fileInfo)} />*/}

		{/*
      <ComposedChart
        width={chartWidth}
        height={600}
        data={usageData}
        margin={{
          top: 20, right: 20, bottom: 20, left: 20,
        }}
      >
				<CartesianGrid stroke="#f5f5f5" />
				<XAxis dataKey="date"/>
        <YAxis/>
        <Tooltip />
        <Legend />
				<Bar dataKey="1001" barSize={10} fill="#ff0000" />
        <Bar dataKey="1002" barSize={20} fill="#00ff00" />

			</ComposedChart>
      */}

    {items.map((item)=>{
      //      const item=items.find((ob)=>ob.id===key)
      return <span key={item.id}>{item && <Button color={selectedItems.includes(item.id)?"success":"secondary"} onClick={()=>selectItems(item.id)}>{item.SKU} {item.name1}</Button>}</span>
    })}


      <ScatterChart
        width={chartWidth}
        height={600}
        margin={{
          top: 20, right: 20, bottom: 20, left: 20,
        }}
      >
				<CartesianGrid stroke="#f5f5f5" />
				<XAxis type="number" dataKey="x"/>
        <YAxis type="number" dataKey="y"/>
        {/*<Legend />*/}

        {Object.values(usageDatax).map((ob:any,index:number)=>(
					<Scatter key={index}
						name={ob.name}
						data={ob.usage}
						fill={ob.color}
						line
						shape={()=>{return null}}
					/>
				))}

        {Object.values(usageDatax).map((ob:any,index:number)=>(
					<Scatter key={"xx"+index}
						name={ob.name}
						data={usageMaths[ob.item.id].forecast(0)}
						fill={ob.color}
						line={{strokeWidth:1}}
						shape={()=>{return null}}
					/>
				))}
  

				{Object.values(usageDatax).map((ob:any,index:number)=>(
					<Scatter key={"x1"+index}
						name={ob.name}
						data={usageMaths[ob.item.id].forecast(usageMaths[ob.item.id].standardDeviationRange())}
						fill={ob.color}
						line={{strokeWidth:1}}
						shape={()=>{return null}}
					/>
				))}

				{Object.values(usageDatax).map((ob:any,index:number)=>(
					<Scatter key={"x2"+index}
						name={ob.name}
						data={usageMaths[ob.item.id].forecast(-usageMaths[ob.item.id].standardDeviationRange())}
						fill={ob.color}
						line={{strokeWidth:1}}
						shape={()=>{return null}}
					/>
				))}

{/*						data={[...ob.usage,{x:(end.getTime()-start.getTime())/1000/60/60/24,y:ob.usage[ob.usage.length-1].y}]}*/}
					
			</ScatterChart>


				<Row>
					<Col>Item</Col>
					<Col className="text-right">Average</Col>
					<Col className="text-right">Standard Deviation</Col>
					<Col className="text-right">Safety Storage</Col>
					<Col className="text-right">ROP</Col>
					<Col className="text-right">EOQ</Col>
				</Row>


			{Object.values(usageDatax).map((ob:any,index:number)=>{
        
        const itemData=sortedUsage.filter((o2)=>(o2.item.id===ob.item.id));

				//				const {usage:u3,orders:o3}=filterData(ob.name)
				const usageMath=usageMaths[ob.item.id] //new UsageMath(ob.item,u3,o3)
        //				usageMath.info()
        return (<><Row key={index}>
          <Col><Button color="link" onClick={()=>toggleDetails(ob.item.id)}>{ob.name}</Button></Col>
					<Col className="text-right">{round(usageMath.average())}</Col>
					<Col className="text-right">{round(usageMath.standardDeviationRange())}</Col>
					<Col className="text-right">{round(usageMath.safetyStorage())}</Col>

					<Col className="text-right">{round(usageMath.reOrderPoint())}</Col>
					<Col className="text-right">{round(usageMath.EOQ())}</Col>


        </Row>
        {selectedDetails.includes(ob.item.id) && <Row>
          <Col>
            {itemData.map((item)=>(
              <div onClick={()=>setEditUsage({...item,quantity:-item.quantity})}> {item.date.printdate()} {-item.quantity}</div>
            ))}
          </Col>
        </Row>}
        </>)
			})}

	{/*
   <div className="App">
			<header className="App-header">


			<table>
				<tbody>
				<tr><td>ROP</td><td><input type="number" onChange={(e)=>setROP(parseFloat(e.target.value))} value={ROP}/></td></tr>
				<tr><td>leadTime</td><td><input type="number" onChange={(e)=>setLeadTime(parseFloat(e.target.value))} value={leadTime}/></td></tr>
				<tr><td>SS</td><td><input type="number" onChange={(e)=>setSS(parseFloat(e.target.value))} value={SS}/></td></tr>
				<tr><td>EOQ</td><td><input type="number" onChange={(e)=>setEOQ(parseFloat(e.target.value))} value={EOQ}/></td></tr>
				<tr><td>sellsOut</td><td><input type="number" onChange={(e)=>setSellsOut(parseFloat(e.target.value))} value={sellsOut}/></td></tr>
				</tbody>
			</table>

      <ComposedChart
        width={chartWidth}
        height={600}
        data={stock}
        margin={{
          top: 20, right: 20, bottom: 20, left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="stock" barSize={20} fill="#413ea0" />
				<Line type="monotone" dataKey="ROP" stroke="#ff7300" dot={false}/>
        <Line type="monotone" dataKey="SS" stroke="#ff7300" dot={false}/>

			</ComposedChart>
     </header>
		</div>
    */}
    <EditModal item={editUsage} close={saveUsage}/>
		</div>
	);
}


const mapStateToProps = (state: AppState) => ({
	items: state.chat.items,
	usage: state.chat.usage,
	orders: state.chat.orders,
});

export default connect(
  mapStateToProps,
  { dbUpdate, thunkSendMessage }
)(Example);

