import { LanguageStrings } from './base-strings';

export const en: LanguageStrings = {
	'routes.home':'/',
	'routes.warehouse':'/warehouse',
	'routes.items':'/items',
	'routes.suppliers':'/suppliers',
	'routes.orders':'/orders',
	'routes.usage':'/usage',

	'sidebar.home':'Home',
	'sidebar.chart':'Chart',

	'sidebar.warehouse':'Warehouse',
	'sidebar.items':'Items',
	'sidebar.suppliers':'Suppliers',
	'sidebar.orders':'Orders',
	'sidebar.usage':'Usage',

};
