import React from 'react';

import { useLocation } from 'react-router-dom';

import { LocalizedLink } from './i18n/LocalizedLink';
import { Link } from 'react-router-dom';

import { AppRoute, AppLanguage } from './i18n/const';

import { appStrings } from './i18n';

import { useIntl } from 'react-intl';

import { FormattedMessage } from 'react-intl';

//const classes=require('./MenuBar.module.scss');

interface IProps {
}

const MenuBar: React.FC<IProps> = (props: IProps) => {
	const { locale, messages } = useIntl();
	const { pathname } = useLocation();

  const [, route] = pathname.split(locale);
  const routeKey = Object.keys(messages).find(key => messages[key] === route);

	const getMatchingRoute = (language:string) => {
		if(routeKey) {
			const matchingRoute = (appStrings as any)[language][routeKey];
			return `/${language}`+ matchingRoute;
		}
		return `/${language}`;
  }

		/*
Dashboard
Tuotetiedo
Tuotelistaus
Tuotesivu: perustiedot, riitto, varastosaldo,
Tuotesivu: tulossaolevat täydennykset,
Tuotesivu: kulutusgraafi/ennuste
Ostomoduuli
Ostoehdotusket
Ostotilauslista
Yksittäinen tilaus
		 */
	return(<div className="sidenav">
		<LocalizedLink className={routeKey===AppRoute.Home?"active":""} to={AppRoute.Home}><i className="fas fa-home"/><span><FormattedMessage id="sidebar.home"/></span></LocalizedLink>

		<LocalizedLink className={routeKey===AppRoute.Items?"active":""} to={AppRoute.Items}><i className="fas fa-boxes"/><span><FormattedMessage id="sidebar.items"/></span></LocalizedLink>
		<LocalizedLink className={routeKey===AppRoute.Suppliers?"active":""} to={AppRoute.Suppliers}><i className="fas fa-industry"/><span><FormattedMessage id="sidebar.suppliers"/></span></LocalizedLink>
		<LocalizedLink className={routeKey===AppRoute.Orders?"active":""} to={AppRoute.Orders}><i className="fas fa-file-alt"/><span><FormattedMessage id="sidebar.orders"/></span></LocalizedLink>
		<LocalizedLink className={routeKey===AppRoute.Usage?"active":""} to={AppRoute.Usage}><i className="fas fa-chart-bar"/><span><FormattedMessage id="sidebar.usage"/></span></LocalizedLink>
		<LocalizedLink className={routeKey===AppRoute.Warehouse?"active":""} to={AppRoute.Warehouse}><i className="fas fa-warehouse"/><span><FormattedMessage id="sidebar.warehouse"/></span></LocalizedLink>


		{Object.keys(AppLanguage).map( (lang) => {
			return (
				<Link
					key={lang}
					to={getMatchingRoute((AppLanguage as any)[lang])}
				><span>{lang}</span></Link>
			)
		})}


  </div>);
}

export default MenuBar;
