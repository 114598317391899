import React, { useState, useEffect } from 'react';

import { Input, Label, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { ISupplier } from "../store/chat/types";

import { FormattedMessage } from 'react-intl';

interface IProps {
	item: ISupplier | null;
	close: (item:ISupplier | null)=>void;
}

//const classes=require('./App.module.scss');

const EditModal: React.FC<IProps> = ({
	item,
	close,
}) => {
	const [editItem, setEditItem]=useState<ISupplier | null>(item);

	const up = (name:string) => (e:any) => {
			setEditItem({...editItem,[name]:e.target.value} as ISupplier)
	}

	useEffect(()=>{
		setEditItem(item);
	},[item]);

	return (
		<Modal isOpen={editItem!==null}>
        <ModalHeader toggle={()=>close(null)}>Edit Item</ModalHeader>
				<ModalBody>
					{editItem && <>
					
						<Label>Name</Label>
						<Input onChange={up("name")} value={editItem.name}/>

						<Label>Delivery Time</Label>
						<Input onChange={up("deliveryTime")} value={editItem.deliveryTime}/>

						<Label>Shipping Costs</Label>
						<Input onChange={up("shippingCosts")} value={editItem.shippingCosts}/>

					</>}
        </ModalBody>
        <ModalFooter>
					<Button color="primary" onClick={()=>close(editItem)}>Save</Button>

        </ModalFooter>
			</Modal>
	)

}

export default EditModal;
